@use "sass:math";
@use '~@THC/styles/core' as core;

$makes: abarth, acura, alfa-romeo, alpina, alpine, ariel, aston-martin, audi, austin, bedford, bentley, bmw, bristol,
	bugatti, buick, cadillac, caterham, chevrolet, chrysler, citroen, cupra, dacia, daewoo, daf, daihatsu, daimler, datsun,
	dodge, ds, ferrari, fiat, ford, geely, gm, gmc, great-wall, harley-davidson, holden, honda, hummer, hyundai, infiniti,
	isuzu, isuzu-trucks, iveco, jaguar, jcb, jeep, kawasaki, kia, koenigsegg, lamborghini, lancia, land-rover, ldv, lexus,
	london-taxi-company, lotus, man, maserati, maybach, mazda, mclaren, mercedes, mg, microcar, mini, mitsubishi, morgan,
	morris, nissan, noble, opel, pagani, perodua, peugeot, piaggio, polestar, porsche, proton, ram, renault, rolls-royce,
	rover, saab, scania, seat, skoda, smart, ssangyong, subaru, suzuki, talbot, tata, tesla, toyota, triumph, tvr,
	vauxhall, volkswagen, volvo, westfield, wolseley, yamaha;

.makeLogo {
	&::before {
		$size: core.$space * 14;

		background-position: center;
		background-size: cover;
		content: '';
		display: inline-block;
		height: $size;
		vertical-align: middle;
		width: $size;
	}

	&.small::before {
		$size: core.$space * 12;

		height: $size;
		width: $size;
	}

	@each $make in $makes {
		&.#{$make}::before {
			background-image: url('./logos/png/#{$make}.png');
		}

		&.#{$make}.webp::before {
			background-image: url('./logos/webp/#{$make}.webp');
		}
	}
}

:export {
	makes: $makes;
}
